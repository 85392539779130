import React from 'react';
import Page from '../components/Page';
import SEO from '../components/seo';
import Home from '../components/Home';

export const query = graphql`
  query {
    STEAK1: file(relativePath: { eq: "steak1.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Page imgData={data}>
    <meta name="theme-color" content="#000000" />
    <SEO
      title="Liberty Call Distilling Co."
      keywords={[
        'San Diego',
        'Distilleries',
        'Craft',
        'Spirits',
        'Cocktails',
        'Distillery',
        'Whiskey',
        'Gin',
        'Rum',
        'Vodka',
        'Liquor',
        'Local',
        'California',
      ]}
    />
    <Home />
  </Page>
);

export default IndexPage;
